import styled from "styled-components";
import { LIGHT_GRAY, LIGHTER_GRAY } from "./colors";

export const PlainButton = styled.button`
  background-color: ${LIGHT_GRAY};
  border: none;
  color: black;
  padding: 0.5em 1em;
  transition: background-color 75ms ease-in;

  &:hover {
    background-color: ${LIGHTER_GRAY};
    cursor: pointer;
  }
`;
