import { ApiResponse, TransformedQuestion } from "./domain";

export const API_ROOT = "https://api.mainittu.fi/v1/npm-or-rnn";

function shuffleQuestions(response: ApiResponse): TransformedQuestion[] {
  return response.map(({ npm, rnn }) => {
    const pairs: TransformedQuestion = [
      { type: "npm", value: npm },
      { type: "rnn", value: rnn }
    ];

    if (Math.random() > 0.5) {
      return [pairs[1], pairs[0]];
    } else {
      return pairs;
    }
  });
}

export async function fetchQuestions() {
  const res = await fetch(API_ROOT);
  const responseBody: ApiResponse = await res.json();
  return shuffleQuestions(responseBody);
}
