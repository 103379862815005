export interface QuestionPair {
  npm: string;
  rnn: string;
}
export type ApiResponse = QuestionPair[];

export type TransformedValue = { type: "npm" | "rnn"; value: string };
export type TransformedQuestion = [TransformedValue, TransformedValue];

export interface Stats {
  correct: number;
  total: number;
}

export type GameState =
  | { state: "Welcome" }
  | { state: "Loading" }
  | {
      state: "Playing";
      questions: TransformedQuestion[];
      currentQuestion: number;
      stats: Stats;
      answered: boolean;
    }
  | { state: "Finished"; stats: Stats };

export function getRightAnswer(question: TransformedQuestion): React.ReactNode {
  return question[0].type === "npm" ? question[0].value : question[1].value;
}

export function calculateCorrectPercentage(stats: Stats) {
  return ((stats.correct / stats.total) * 100).toFixed(2);
}
