import React from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { PlainButton } from "./presentational";
import { fetchQuestions, API_ROOT } from "./api";
import {
  TransformedQuestion,
  TransformedValue,
  Stats,
  GameState,
  calculateCorrectPercentage,
  getRightAnswer
} from "./domain";

const INITIAL_STATE: GameState = { state: "Welcome" };

/*const INITIAL_STATE: GameState = {
  state: "Playing",
  questions: [
    [{ type: "npm", value: "left-pad" }, { type: "rnn", value: "north-pad" }]
  ],
  currentQuestion: 0,
  answered: "unanswered",
  stats: { correct: 0, total: 0 }
};*/

const Welcome: React.FC<{ onNext(): void }> = ({ onNext }) => {
  return (
    <div>
      <h2>NPM or RNN?</h2>
      <p>
        You will be presented with 10 pairs of NPM package names. In each pair,
        one of the packages is real, while the other one was randomly generated
        using a{" "}
        <a href="https://en.wikipedia.org/wiki/Recurrent_neural_network">RNN</a>
        .
      </p>
      <details open>
        <summary>Boring details</summary>
        <p>
          This is a shameless rip-off of{" "}
          <a href="http://java.metagno.me/">
            <i>Java: Real or Not?</i>
          </a>
        </p>
        <p>
          The network was trained on the top 250K NPM packages, using{" "}
          <a href="https://github.com/minimaxir/textgenrnn">textgenrnn</a>. The
          made-up package names were generated offline, because despite my best
          efforts I couldn't figure out how to deploy the model to AWS Lambda.{" "}
          <a href={API_ROOT}>The API</a> uses about 9000 pre-generated package
          names and combines them with{" "}
          <a href="https://www.npmjs.com/package/all-the-package-names">
            about a million
          </a>{" "}
          real package names.
        </p>
        <p>
          Here's <a href="https://twitter.com/paavohtl">my Twitter.</a>
        </p>
      </details>
      <p />
      <PlainButton onClick={onNext}>
        <span>Start.</span>
      </PlainButton>
    </div>
  );
};

const AnswerButton = styled(PlainButton)`
  margin: 1em;
  flex-grow: 1;
`;

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Playing: React.FC<{
  question: TransformedQuestion;
  onAnswer(value: TransformedValue): void;
  answered: boolean;
  onNext(): void;
  stats: Stats;
}> = ({ onAnswer, question, answered, stats, onNext }) => {
  return (
    <div>
      <p>Which one is real?</p>
      <AnswerContainer>
        <AnswerButton onClick={() => onAnswer(question[0])} disabled={answered}>
          {question[0].value}
        </AnswerButton>
        <span>OR</span>
        <AnswerButton onClick={() => onAnswer(question[1])} disabled={answered}>
          {question[1].value}
        </AnswerButton>
      </AnswerContainer>
      <p>
        {stats.correct} / {stats.total}{" "}
        {stats.total === 0 ? null : <>({calculateCorrectPercentage(stats)}%)</>}
      </p>
      {answered ? (
        <>
          <p>The right answer was {getRightAnswer(question)}.</p>
          <PlainButton onClick={onNext}>Next</PlainButton>
        </>
      ) : null}
    </div>
  );
};

const Finished: React.FC<{ stats: Stats; onNext(): void }> = ({
  stats,
  onNext
}) => {
  return (
    <div>
      <p>You got {calculateCorrectPercentage(stats)}% right.</p>
      <PlainButton onClick={onNext}>Do it again.</PlainButton>
    </div>
  );
};

const Game = () => {
  const [gameState, setGameState] = React.useState<GameState>(INITIAL_STATE);

  const pushGameState = (state: GameState) => {
    history.pushState(state, document.title);
    setGameState(state);
  };

  React.useEffect(() => {
    history.replaceState(gameState, document.title);
    window.onpopstate = event => {
      setGameState(event.state);
    };
    return () => {
      window.onpopstate = null;
    };
  }, []);

  switch (gameState.state) {
    case "Loading": {
      return <span>Loading...</span>;
    }

    case "Welcome": {
      const onNext = async () => {
        setGameState({ state: "Loading" });
        const questions = await fetchQuestions();
        pushGameState({
          state: "Playing",
          questions,
          currentQuestion: 0,
          answered: false,
          stats: { correct: 0, total: 0 }
        });
      };

      return <Welcome onNext={onNext} />;
    }

    case "Playing": {
      const { answered, stats, questions, currentQuestion } = gameState;
      const question = questions[currentQuestion];

      const onAnswer: (value: TransformedValue) => void = answer => {
        const wasCorrect = answer.type === "npm";
        const { correct, total } = stats;
        pushGameState({
          ...gameState,
          answered: true,
          stats: {
            correct: correct + (wasCorrect ? 1 : 0),
            total: total + 1
          }
        });
      };

      const onNext = () => {
        if (currentQuestion === questions.length - 1) {
          pushGameState({ state: "Finished", stats });
        } else {
          pushGameState({
            ...gameState,
            answered: false,
            currentQuestion: currentQuestion + 1
          });
        }
      };

      return (
        <Playing
          question={question}
          answered={answered}
          stats={stats}
          onAnswer={onAnswer}
          onNext={onNext}
        />
      );
    }

    case "Finished":
      const { stats } = gameState;
      return (
        <Finished
          stats={stats}
          onNext={() => {
            pushGameState({ state: "Welcome" });
          }}
        />
      );
  }
};

const AppContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-size: 16pt;
  max-width: 650px;
  margin-top: 80px;
  padding: 1em;
`;

const App: React.FC = () => {
  return (
    <AppContainer>
      <Game />
    </AppContainer>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
